<template>
   <el-container class="mapIndex">
  <div class="mapIndex_top">
      <el-button @click="getMyLocation()">默认</el-button>
      <el-select v-model="form.province_id" class="width_160 ml8" filterable placeholder="可搜索省份"  @change="areaChange1">
        <el-option
          v-for="item in provinceData"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select placeholder="请选择市" class="width_160 ml8" v-model="form.city_id" filterable  @change="areaChange2">
        <el-option
          v-for="item in cityData"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select placeholder="请选择区" class="width_160 ml8" filterable v-model="form.district_id" @change="areaChange3">
        <el-option v-for="item in districtData" :key="item.id" :label="item.name" :value="item.id" >
        </el-option>
      </el-select>
       <el-input class="width_160 ml8" v-model="form.address" placeholder="输入详细地址" @change="areaChange4"></el-input>
  </div>
  <div class="mapIndex_main" id="mapIndex_main"  v-loading="loading">
  </div>
  <div class="boxList">
      <div class="boxList_title">
        当前有 <span style="color:#2466FA">{{meta.total}}</span> 家
      </div>
      <div style="margin-top:30px;">
        <div class="boxList_main" v-for="(item,indx) in listData" :key="indx" @click="curPositon(item,indx)">
         <div class="boxList_main_top">
           <span class="box_indx">{{indx+1}}</span>
          {{item.name}}
         </div>
         <div class="detail_address">
          <i class="el-icon-location-outline"></i>
           <span>{{item.address}}</span>
         </div>
          
        </div>
        <div class="pageStyle">
         <PageComponents  :total="meta.total"
              styles="text-align:center;margin-top: 20px;"
              :page-size='6'
              :layout='layout'
              :page="page"
               v-show="meta.last_page>1"
              @currentPageChange="currentPageChange" /></div>
      </div>
  </div>
</el-container>
</template>
<script src="https://map.qq.com/api/js?v=2.exp&key=CB2BZ-KWQK5-RMCI2-QV7TT-RT3T2-MFBEX"></script>
<script src="https://3gimg.qq.com/lightmap/components/geolocation/geolocation.min.js"></script>
<script>
import PageComponents from '../../components/PageComponents.vue';
export default {
  data(){
    return{
      layout:'total, prev, next, jumper',
      provinceData:[],
      cityData:[],
      districtData:[],
      meta:{
        total:0
      },
      loading:true,
      form:{
        province_id:'',
        district_id:'',
        city_id:'',
        address: ''
      },
      longitude:0,//经度
      latitude:0,//纬度
      city:'',
      province:'',
      latData:[],
      page:1,
      curIndex:0,
      listData:[] // 页面列表展示
    }
  },
   components: {
    PageComponents
  },
  created(){
    this.getProvinces() // 获取省份
  },
  mounted(){
      this.getMyLocation();
  },
  watch:{
    page(){
       this.getListdata()
    }
  },
  methods:{ 
        curPositon(item,i){
            this.latitude = item.latitude
            this.longitude = item.longitude
            let latLng = new qq.maps.LatLng(item.latitude, item.longitude);
            this.setMap(this.province,latLng,this.latitude,this.longitude,i)
        },
          currentPageChange(val){ // 页码改变的时候
            this.page = val
          },
          getListdata(){ // 分页列表数据
            this.$superHttp
            .get(this.URL.superUrl.organsMap,{params:{
              province_id:this.form.province_id,
              city_id:this.form.city_id,
              district_id:this.form.district_id,
              name:this.form.address,
              page:this.page,
              limit:6
            }})
            .then(res=>{
              this.listData = res.data.data.organs
              this.meta = res.data.data.meta
            })
            .catch(err=>{
              console.log(err)
            })
          },
          searchData(){ // 筛选用于地图展示
            this.$superHttp
            .get(this.URL.superUrl.organsMap,{params:{
              province_id:this.form.province_id,
              city_id:this.form.city_id,
              district_id:this.form.district_id,
              address:this.form.address
            }})
            .then(res=>{
              this.latData = res.data.data.organs
              this.setMap(this.province,'',this.latitude,this.longitude,0)
            })
            .catch(err=>{
              console.log(err)
            })
          },
          getAreaCode(province,city){ // 获取默认定位时 的对应id
            this.$superHttp
            .get(this.URL.superUrl.areaCode,{params:{
              province,
              city
            }})
            .then(res=>{
              let data = res.data.data
              if(data.province_id){
                this.form.province_id = data.province_id
                this.areaChange1()
              }
              if(data.city_id){
                this.form.city_id = data.city_id
                this.areaChange2()
              }
             
            })
            .catch(err=>{
              console.log(err)
            })
          },
          
           //定位获得当前位置信息
            getMyLocation() {
                this.page = 1
                var geolocation = new qq.maps.Geolocation("CB2BZ-KWQK5-RMCI2-QV7TT-RT3T2-MFBEX", "智慧双创");
                geolocation.getLocation(this.showPosition, this.showErr);
                //geolocation.getLocation(this.showPosition, this.showErr);//或者用getLocation精确度比较高
            },
            showPosition(position) {
                if(position){
                    this.latitude = position.lat;
                    this.longitude = position.lng;
                    this.city = position.city;
                    this.province = position.province
                }
               
                this.getAreaCode(this.province,this.city)
                let val=''
                if(this.city){
                  val = this.city
                }else if(this.province){
                  val = this.province
                }
                this.setMap(val,'',this.latitude,this.longitude,0);
            },
            showErr() {
                console.log("定位失败");
                this.getMyLocation();//定位失败再请求定位，测试使用
            },
//第二部分
        //位置信息在地图上展示
            setMap(val,latLng,lati,longit,i) {
                this.curIndex = i
                //步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
                //设置地图中心点
                // var myLatlng = new qq.maps.LatLng(this.latitude,this.longitude);
                //定义工厂模式函数
                var myOptions = {
                  zoom: 13,               //设置地图缩放级别
                  center: new qq.maps.LatLng(lati,longit),    //设置中心点样式
                  disableDefaultUI: true,   
                  mapTypeId: qq.maps.MapTypeId.ROADMAP  //设置地图样式详情参见MapType
                }
                // //获取dom元素添加地图信息
                var map = new qq.maps.Map(document.getElementById("mapIndex_main"), myOptions);
//第三部分
              //给定位的位置添加图片标注
               
                let that = this
                var citylocation = null
                var info = new qq.maps.InfoWindow({ map: map });      //添加提示窗
                citylocation = new qq.maps.CityService({
                  complete : function(results){
                        var markData = that.latData // 动态添加标记点
                        let curIndex = that.curIndex
                        if(markData.length>0){
                          let latLng2 =  new qq.maps.LatLng(markData[curIndex].latitude,markData[curIndex].longitude)
                           map.setCenter(latLng2); // 设置中心点
                       
                        }else{
                           let latLng2 = results.detail.latLng
                            map.setCenter(latLng2);
                            return false
                        }
                      for(var i=0;i<markData.length;i++){
                        // console.log()
                        let data = markData[i]
                        var marker = new qq.maps.Marker({
                          position: new qq.maps.LatLng(data.latitude,data.longitude),
                          map: map
                        });
                        qq.maps.event.addListener(marker, 'click', function() {    //获取标记的点击事件
                            info.open();  //点击标记打开提示窗
                            info.setContent('<div class="mapInfo"><p class="center">'+data.name+'</p></div>');  //***设置提示窗内容（这里用到了marker对象中保存的数据）
                            info.setPosition(new qq.maps.LatLng(data.latitude, data.longitude));  //提示窗位置
                        });
                      }
                      that.loading = false
                  }
              });
              if(latLng){ // 区和详细地址改变的时候 用经纬度，省市用 名称
                citylocation.searchCityByLatLng(latLng);  // 经纬度
              }else{
                citylocation.searchCityByName(val); // 城市名称 省市
              }
              this.loading = false
            },
     areaChange1() { // 当省市发生改变时调用
      let that = this;
      that.getArea(this.form.province_id).then(res =>  {
        that.cityData = res.data.data
      })
      if(this.form.city_id){
        this.form.city_id = ''
      }
      if(this.form.district_id){
        this.form.district_id = ''
        this.districtData = []
      }
      let data = this.provinceData
      data.map((v)=>{
        if(v.id===this.form.province_id){
          this.province = v.name
        }
      })
      this.searchData()
      this.getListdata()
    
      // this.showPosition()
    },
    areaChange2() { // 当市级发生改变时调用
      let that = this;
      that.getArea(this.form.city_id).then(res =>  {
        that.districtData = res.data.data
      })
      if(this.form.district_id){
        this.form.district_id = ''
        this.districtData = []
      }
     let data = this.cityData
      data.map((v)=>{
        if(v.id===this.form.city_id){
          this.city = v.name
        }
      })
      this.searchData()
      this.getListdata()
    },
    getLal (address) { // 通过地址获取经纬度
    var geocoder = new qq.maps.Geocoder();
		//对指定地址进行解析
		geocoder.getLocation(address);
		//设置服务请求成功的回调函数
		geocoder.setComplete((res) => {
            this.latitude = res.detail.location.lat
            this.longitude = res.detail.location.lng
            let latLng = new qq.maps.LatLng(this.latitude, this.longitude);
            this.setMap(this.city,latLng,this.latitude,this.longitude,0)
		});
		//若服务请求失败，则运行以下函数
		geocoder.setError((err) => {
      error('地址输入错误')
		})
	},
    areaChange3(){ // 选择区县的时候
      var geocoder = new qq.maps.Geocoder()
      let data = this.districtData
      data.map((v)=>{
        if(v.id===this.form.district_id){
           this.district = v.name
        }
      })
      let address = this.province+this.city+this.district
      this.getLal(address)
      this.searchData()
      this.getListdata()
    },
    areaChange4(){ // 详细地址
      if(this.form.address){
        let address = this.province + this.city+this.district+this.form.address
         this.getLal(address)
      }
    },
    getProvinces() { // 获取省的接口数据
      this.getArea(0).then(res => {
        this.provinceData = res.data.data
      })
    },
    getArea(pid) { // 请求地区信息的接口
      return this.$superHttp
        .get(this.URL.getArea, { params:{ city_id:pid} })
        .then(res => {
          return res
        })
        .catch(err => {
          console.log(err)
        })
    },
    getEditData(pid,data){ // 编辑时获取数据
       this.getArea(pid).then(res => {
        this[data] = res.data.data
      })
    },
    editAreaData(data,val) { //编辑时获取地区数据
      this.getArea(val).then(res => {
        this[data] = res.data.data
      })
    },
  }
}
</script>
<style lang="less" scoped>
.mapIndex{
  .width_160{
    width:160px;
  }
  .ml8{
    margin-left:8px;
  }
  .mapIndex_top{
    width: 750px;
    position: fixed;
    z-index: 120;
    left: 300px;
    top: 200px;
  }
  .mapIndex_main{
    width:100%;
    height:600px;
  }
  .boxList{
      background: #fff;
      width: 460px;
      height:444px;
      padding:10px;
      box-sizing: border-box;
      position: fixed;
      z-index: 104;
      top:250px;
      left:140px;
     overflow-y:auto;
     .pageStyle{
       position: fixed;
       top:620px;
       left:160px;
     }
      .boxList_title{
        width:440px;
        font-weight:bold;
        color:#333;
        position: fixed;
        background: #fff;
        z-index: 106;
      }
      .boxList_main{
        cursor: pointer;
        .boxList_main_top{
          margin-top: 12px;
          .box_indx{
            width:23px;
            height:23px;
            color: #fff;
            line-height: 23px;
            display: inline-block;
            text-align: center;
            background:rgba(245,166,35,1);
            border-radius: 50%;
          }
        }
        .detail_address{
          padding-left:16px;
          margin-top:4px;
          box-sizing: border-box;
          font-size:14px;
          color:#787878;
        }
      }
    }
}
</style>